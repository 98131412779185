// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-download-jsx": () => import("./../../../src/pages/download.jsx" /* webpackChunkName: "component---src-pages-download-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-job-jsx": () => import("./../../../src/pages/job.jsx" /* webpackChunkName: "component---src-pages-job-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-sale-service-jsx": () => import("./../../../src/pages/sale-service.jsx" /* webpackChunkName: "component---src-pages-sale-service-jsx" */),
  "component---src-pages-supplier-jsx": () => import("./../../../src/pages/supplier.jsx" /* webpackChunkName: "component---src-pages-supplier-jsx" */),
  "component---src-pages-tech-jsx": () => import("./../../../src/pages/tech.jsx" /* webpackChunkName: "component---src-pages-tech-jsx" */),
  "component---src-templates-about-list-jsx": () => import("./../../../src/templates/about-list.jsx" /* webpackChunkName: "component---src-templates-about-list-jsx" */),
  "component---src-templates-case-detail-jsx": () => import("./../../../src/templates/case-detail.jsx" /* webpackChunkName: "component---src-templates-case-detail-jsx" */),
  "component---src-templates-case-list-jsx": () => import("./../../../src/templates/case-list.jsx" /* webpackChunkName: "component---src-templates-case-list-jsx" */),
  "component---src-templates-media-detail-jsx": () => import("./../../../src/templates/media-detail.jsx" /* webpackChunkName: "component---src-templates-media-detail-jsx" */),
  "component---src-templates-media-list-jsx": () => import("./../../../src/templates/media-list.jsx" /* webpackChunkName: "component---src-templates-media-list-jsx" */),
  "component---src-templates-privacy-list-jsx": () => import("./../../../src/templates/privacy-list.jsx" /* webpackChunkName: "component---src-templates-privacy-list-jsx" */),
  "component---src-templates-product-detail-jsx": () => import("./../../../src/templates/product-detail.jsx" /* webpackChunkName: "component---src-templates-product-detail-jsx" */),
  "component---src-templates-product-list-jsx": () => import("./../../../src/templates/product-list.jsx" /* webpackChunkName: "component---src-templates-product-list-jsx" */),
  "component---src-templates-tech-list-jsx": () => import("./../../../src/templates/tech-list.jsx" /* webpackChunkName: "component---src-templates-tech-list-jsx" */)
}

